import React, { PureComponent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import memoize from 'memoize-one';

import isEqual from 'react-fast-compare';
import { evalShowWhen } from '../../../helpers/helperIndex';

import {
  formSelectUpdated,
  clearRequiredField,
  formSelectSiblingClear,
} from '../../../redux/actions/actionsIndex';

import {
  makeGetInputStateByField,
  makeGetInputStateByFilterWhen,
} from '../../../redux/selectors/selectorIndex';
import { FormContext } from '../context/contextIndex';
import { SelectOld, SelectContainerOld, SelectWrapperOld } from '../atomicStyledComponents/stylesIndex';

const memoEvalShowWhen = memoize(evalShowWhen);

class Dropdown extends PureComponent {
  constructor(props) {
    super(props);
    this.key = uuidv4();
  }

  componentDidUpdate(prevProps, prevState) {
    return null;
  }

  getNextSiblingFieldName = () => {
    const current = document.getElementById(`${this.props.id}`);
    if (!current.parentNode && current.parentNode.children.length <= 0) return null;
    const siblings = current.parentNode.children;
    const currentIdx = Array.from(siblings).indexOf(current);
    const nextSibling = Array.from(siblings).slice(currentIdx + 1, currentIdx + 2)[0];
    return nextSibling ? nextSibling.childNodes[0].getAttribute('name') : null;
  };

  isEthnicityRelated = (siblingId) => {
    if (typeof siblingId !== 'string') return false;
    return !!siblingId.includes('FamilyHistory.Ethnicity');
  };

  handleSelectChange = (e) => {
    const { value } = e.target;
    // Converted because <option> cannot handle null values out of the box
    const convertedValue = value === '' ? null : value;
    let valuePrev
    let action
    if(this.props.optValue){
      if(!value){
        action = 'delete'
        valuePrev = this.props.optValue
      } else {
        valuePrev = this.props.optValue
      action = "change"
      }
    } else if (!this.props.optValue) {
      valuePrev = null
      action = "add"
    }

    // Clear sibling only for Ethnicity related fields
    if (this.props.formContext === 'checkin') {
      const siblingFiledName = this.getNextSiblingFieldName();
      if (this.isEthnicityRelated(siblingFiledName)) {
        this.props.siblingValueClear(siblingFiledName, this.props.prenuvoID);
      }
    }

    this.props.handleSelect(
      this.props.field,
      convertedValue,
      valuePrev,
      action,
      this.props.parent,
      this.props.prenuvoID,
    );
  };

  render() {
    return (
      <SelectContainerOld key={this.key} context={this.props.context}>
        <SelectWrapperOld
          className="dropdown"
          context={this.props.formContext}
          disabled={this.props.disabled}
        >
          <SelectOld
            disabled={this.props.disabled}
            id={`${this.props.field}-Select`}
            name={`${this.props.field}-Select`}
            context={this.props.context}
            formContext={this.props.formContext}
            className={`${this.props.field.split('.').join(' ')} dropdown`}
            value={this.props.optValue === null || this.props.disabled ? '' : this.props.optValue}
            onChange={this.handleSelectChange}
          >
            {this.props.formContext === 'reading' && this.props.defaultOpt && (
              <option key="default" value={this.props.defaultOpt}>
                {this.props.defaultOpt}
              </option>
            )}
            {(this.props.formContext !== 'reading' || !this.props.defaultOpt) && (
              <option key="default" value="">
                {this.props.formContext === 'reading' ? `-Select-` : `- Pick an option -`}
              </option>
            )}

            {this.props.options.map((opt,i) => (
              <option key={`${opt.value}-${opt.label}-${i}`} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </SelectOld>
        </SelectWrapperOld>
      </SelectContainerOld>
    );
  }
}

Dropdown.contextType = FormContext;

const mapDispatchToProps = {
  handleSelect: formSelectUpdated,
  clearReq: clearRequiredField,
  siblingValueClear: formSelectSiblingClear,
};

const makeMapStateToProps = (_) => {
  const getSelect = makeGetInputStateByField();
  const getInputStateByFilterWhen = makeGetInputStateByFilterWhen();

  return (state, props) => {
    const select = getSelect(state, props);
    let newProps = {};

    if (select && select.length > 0) {
      if (props.optValue !== select[0].value) {
        newProps = {
          ...newProps,
          optValue: select[0].value,
        };
      }
    }

    // Default values for selects
    if (
      !props.optValue &&
      props.defaults &&
      props.defaults.length > 0 &&
      (!props.checked || props.checked.length < 1)
    ) {
      for (const def of props.defaults) {
        // TRIAGE-1661: Added feature of disabling defaults within the JSON (using "disabled": true)
        //  which prevents defaults to auto-fill on frontend
        if (def.disabled === undefined || def.disabled === false) {
          // const inputs = makeGetFieldFromState(def.defaultWhen.field)(state);
          const defaultWhens = getInputStateByFilterWhen(state, def.defaultWhen);
          const defaulted = memoEvalShowWhen(def.defaultWhen, defaultWhens);

          if (defaulted) {
            // const defaultOption = props.options.filter((opt) => opt.value === def.default);

            newProps = {
              ...newProps,
              optValue: def.default,
            };
          }
        }
      }
    }

    return isEqual(newProps, props) ? props : newProps;
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Dropdown);